<template>
  <v-app>
    <service-provider-menu />

    <common-view />

    <common-footer />

    <kdw-Cookiebar-card
      v-model="cookieUpdate"
    />
  </v-app>
</template>

<script>
  export default {
    name: 'ServiceProviderLayout',
    data: () => {
      return { cookieUpdate: false }
    },

    components: {
      ServiceProviderMenu: () => import('@/layouts/serviceprovider/Menu'),
      CommonFooter: () => import('@/layouts/Footer'),
      CommonView: () => import('@/layouts/View'),
    },
  }
</script>
